import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    }
]

export const protectedRoutes = [
    {
        key: 'sidenav.dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    },
    {
        key: 'dashboard.controlhippo',
        path: `${APP_PREFIX_PATH}/controlhippo`,
        component: React.lazy(() => import('views/app-views/controlhippo')),
    },
    {
        key: 'sidenav.customerList',
        path: `${APP_PREFIX_PATH}/customer-list`,
        component: React.lazy(() => import('views/app-views/customerList')),
    },
    {
        key: 'sidenav.invoiceList',
        path: `${APP_PREFIX_PATH}/invoice-list`,
        component: React.lazy(() => import('views/app-views/invoiceList')),
    },
    {
        key: 'sidenav.leads',
        path: `${APP_PREFIX_PATH}/lead`,
        component: React.lazy(() => import('views/app-views/leadList')),
    },
    {
        key: 'dashboard.userProfile',
        path: `${APP_PREFIX_PATH}/user-profile`,
        component: React.lazy(() => import('views/app-views/userProfile')),
    },
    {
        key: 'dashboard.userProfile',
        path: `${APP_PREFIX_PATH}/user-profile/:userId`,
        component: React.lazy(() => import('views/app-views/userProfile')),
    },
    {
        key: 'dashboard.calllog',
        path: `${APP_PREFIX_PATH}/calllog`,
        component: React.lazy(() => import('views/app-views/callLog')),
    },
    {
        key: 'dashboard.smsProfiles',
        path: `${APP_PREFIX_PATH}/sms-profile`,
        component: React.lazy(() => import('views/app-views/smsProfile')),
    },
    {
        key: 'dashboard.smsLog',
        path: `${APP_PREFIX_PATH}/smslog`,
        component: React.lazy(() => import('views/app-views/smsLog')),
    },
    {
        key: 'dashboard.number',
        path: `${APP_PREFIX_PATH}/number`,
        component: React.lazy(() => import('views/app-views/number')),
    },
    {
        key: 'dashboard.addressVerification',
        path: `${APP_PREFIX_PATH}/address-verification`,
        component: React.lazy(() => import('views/app-views/addressVerification')),
    },
    {
        key: 'callingProvider.countryBaseCallingProvider',
        path: `${APP_PREFIX_PATH}/calling-provider/country-base-calling-provider`,
        component: React.lazy(() => import('views/app-views/callingProvider/CountryBaseCallingProvider')),
    },
    {
        key: 'callingProvider.userBaseCallingProvider',
        path: `${APP_PREFIX_PATH}/calling-provider/user-base-calling-provider`,
        component: React.lazy(() => import('views/app-views/callingProvider/UserBaseCallingProvider')),
    },
    {
        key: 'dashboard.cancelledUser',
        path: `${APP_PREFIX_PATH}/cancelledUser`,
        component: React.lazy(() => import('views/app-views/cancelledUser')),
    },
    {
        key: 'dashboard.creditReport',
        path: `${APP_PREFIX_PATH}/creditReport`,
        component: React.lazy(() => import('views/app-views/creditReport')),
    },
    {
        key: 'dashboard.prefixPrice',
        path: `${APP_PREFIX_PATH}/prefixPrice`,
        component: React.lazy(() => import('views/app-views/prefixPrice')),
    },
    {
        key: 'dashboard.reactivatedCustomer',
        path: `${APP_PREFIX_PATH}/reactivated-customer`,
        component: React.lazy(() => import('views/app-views/reactivatedCustomer')),
    },
    {
        key: 'dashboard.dataMining',
        path: `${APP_PREFIX_PATH}/dataMining`,
        component: React.lazy(() => import('views/app-views/dataMining')),
    },
]