import whiteLabelEnvLive from "./whiteLabelEnvLive";

const whiteLabelEnvTest = {};

whiteLabelEnvTest["localhost"] = {
    ...whiteLabelEnvLive["localhost"],
    "REACT_APP_API_URL": "https://staging-chadmin-api.callhippo.com",
};

whiteLabelEnvTest["dev-chadmin.callhippo.com"] = {
    ...whiteLabelEnvLive["chadmin.callhippo.com"],
    "REACT_APP_API_URL": "https://dev-chadmin-api.callhippo.com",
};

whiteLabelEnvTest["staging-chadmin.callhippo.com"] = {
    ...whiteLabelEnvLive["chadmin.callhippo.com"],
    "REACT_APP_API_URL": "https://staging-chadmin-api.callhippo.com",
};

whiteLabelEnvTest["staging-admin.dialerhq.com"] = {
    ...whiteLabelEnvLive["admin.dialerhq.com"],
    "REACT_APP_API_URL": "https://staging-admin-api.dialerhq.com",
}

whiteLabelEnvTest["datamining.d2q519jve84yqa.amplifyapp.com"] = {
    ...whiteLabelEnvLive["datamining.d2q519jve84yqa.amplifyapp.com"],
}

export default whiteLabelEnvTest;