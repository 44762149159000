import { parse as tldts } from 'tldts';
const commonEnv = {
    ...process.env,
    "REACT_APP_DOMAIN": tldts(window.location.hostname).domain,
    "REACT_APP_PAGETITLE": "Admin Portal - CallHippo",
    "REACT_APP_LOGO": "/img/callhippo-logo.svg",
    "REACT_APP_LOGO_SMALL": "/img/callhippo-logo-sm.svg",
    "REACT_APP_LOGO_WHITE": "/img/callhippo-logo-white.svg",
    "REACT_APP_LOGO_SMALL_WHITE": "/img/callhippo-logo-sm-white.svg",
    "REACT_APP_AUTH_BACKGROUND": "/img/others/img-17-callhippo.png",
    "REACT_APP_FAVICON": "/callhippo-favicon.png",
    "REACT_APP_LOGO_ALT": "CallHippo",
    "REACT_APP_GOOGLE_SIGNIN_CLIENT_ID": "524143824291-69q0il5ojjek9su64ir4gtk7eruu8ijv.apps.googleusercontent.com",
    "REACT_APP_S3_URL_DOMAIN": "https://callhippo-media.s3.amazonaws.com",
}

const whiteLabelEnvLive = {};

whiteLabelEnvLive["localhost"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
};

whiteLabelEnvLive["chadmin.callhippo.com"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
    "REACT_APP_API_URL": "https://chadmin-api.callhippo.com",
};

whiteLabelEnvLive["datamining.d2q519jve84yqa.amplifyapp.com"] = {
    ...commonEnv,
    "REACT_APP_IS_CALLHIPPO": true,
    "REACT_APP_API_URL": "https://datamining-api.callhippo.com",
};

whiteLabelEnvLive["admin.dialerhq.com"] = {
    ...commonEnv,
    "REACT_APP_IS_DIALERHQ": true,
    "REACT_APP_API_URL": "https://admin-api.dialerhq.com",
    "REACT_APP_PAGETITLE": "Admin Portal - DialerHQ",
    "REACT_APP_LOGO": "/img/dialerhq-logo.svg",
    "REACT_APP_LOGO_SMALL": "/img/dialerhq-logo-sm.svg",
    "REACT_APP_LOGO_WHITE": "/img/dialerhq-logo-white.svg",
    "REACT_APP_LOGO_SMALL_WHITE": "/img/dialerhq-logo-sm-white.svg",
    "REACT_APP_AUTH_BACKGROUND": "/img/others/img-17-dialerhq.png",
    "REACT_APP_FAVICON": "/dialerhq-favicon.png",
    "REACT_APP_LOGO_ALT": "DialerHQ",
    "REACT_APP_GOOGLE_SIGNIN_CLIENT_ID": "522147527275-mmk7vs9ufb00lcn5t9l1lmaerljn4nvv.apps.googleusercontent.com",
    "REACT_APP_S3_URL_DOMAIN": "https://wl-call-media.s3.ca-central-1.amazonaws.com",
}

export default whiteLabelEnvLive;